import React from 'react'

function About() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 AboutBanner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>About Us</h1>
                        <p className='blockquote d-none'>Achieves Best-In-Class Power Performance, Area, Schedule</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>About Marquee Semiconductor</h3>
                    <p className='mt-4'>Marquee Semiconductor is an innovative provider of semiconductor design services with technical expertise in connectivity. Our company was founded in July 2019 to work with customers as partners and strive to provide design services do deliver advanced SoCs and chiplets. We utilize our connectivity skills to provide customers with both engineering resources and turnkey services, depending on their preferences and requirements. With engineering sites around the world – India, Philippines, Singapore, Malaysia and the USA, Marquee differentiates itself through a policy of Innovation, Integrity, and Transparency in all of our customer engagements.</p>
                    <p>We take a fully transparent approach to how we provide customers with services so our clients always know exactly how a project is progressing, in real-time. Give us a chance to work with you and we’ll show you.</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 mt-4 '>
                    <h3 className='text-uppercase text-mcolor pt-5'>Our Vision <span className='text-info'>&</span> Mission</h3>
                    <p className='text-uppercase blockquote text-mcolor mt-3'>Vision</p>
                    <p>We believe that connectivity within and between chips is a critical aspect of modern semiconductor designs. Our vision is to leverage this know-how to make our customers’ products perform at their highest potential and to make them successful in the marketplace.</p>
                    <p className='text-uppercase blockquote text-mcolor mt-3'>Mission</p>
                    <p>To leverage our connectivity experience, know-how & skills to deliver advanced designs on schedule and within budget.</p>
                    
                    
                </div>
                <div className='col-md-6 d-flex align-items-center justify-content-center'>
                    <img src='./images/mission-vision.jpg' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12 mt-4 '>
                    <h3 className='text-uppercase text-mcolor pt-5'>Values</h3>
                </div>
                <div className='col-md-12 d-flex align-items-center justify-content-center'>
                    <img src='./images/values.png' alt='Marquee Simiconductor' className='img-fluid p-3'/>
                </div>
            </div>

            <div className='row pb-5'>
                <div className='col-md-12 mt-5'>
                    <h3 className='text-uppercase text-mcolor'>LEADERSHIP</h3>
                    {/*<p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p>*/}
                </div>
                {/*<div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/purna.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div>
                <div className='col-md-8 mt-4 leadershipSection d-flex align-items-center justify-content-center'>
                    <div>
                        <p className='text-uppercase text-mcolor'> <span className='blockquote'>PURNA MOHANTY,</span> CO-FOUNDER AND CEO</p>
                        <p className='text-justify'>Purna Mohanty is the co-founder and CEO of the company. Prior to co-founding Marquee Semiconductor, Purna held the position of vice president engineering and operation at Tessolve USA. Purna brings in 24 years of experience and expertise in engineering, sales, business development, marketing and operations, and connecting these specialties to build successful businesses. Purna brings in entrepreneurial vision to the company – with his experience in co-founding or leading successful startups – ASIC Architect, Aims Technology, Kool Chip, Platys Communication, SmartSAN Network. Purna had provided engineering and operational leadership at established companies – Adaptec, Semtech (Gennum), Broadcom (Gadzoox, LSI Logic), Tessolve Semiconductor. Purna holds 5 US patents in the area of SoC verification and design. Purna holds a BSEE from NIT Rourkela, India and MSEE from University of Toledo, OH, USA.</p>
                    </div>
                </div>
                
                <div className='col-md-8 mt-4'>
                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>DAM BUI,</span> CO-FOUNDER, VP OF SYSTEM MODELING AND VERIFICATION</p>
                    <p className='text-justify'>Dam Bui is the co-founder and VP of Verification and System Modeling. At Marquee Semiconductor, Dam is pioneering the development of methodologies for design and verification of complex digital and AMS chips from specification to silicon and firmware. Prior to joining Marquee, Dam was the principal engineer at Socionext, and prior to that, Director of Verification and Modeling at Invecas, responsible for verification methodology, execution and modeling of complex SoCs and high-speed interfaces. An engineer and technologist by heart, Dam has 25+ years of experience in the industry and have pioneered on several technology areas ranging from HW-SW co-verification, system modeling, bring-in verification methodologies into emulation. Dam specializes in high performance and storage areas – Coherent NoC, NVMe, PCIe, SSD etc. Dam was the co-founder of ArchSilc working on developing accelerated PCIe VIPs that can run inside emulators and FPGAs. Dam had held several engineering leadership positions at Aims Technology, Kool chip, Broadcom, Quicklogic. Dam holds BS in EECS with Hons from UC Berkeley.</p>
                </div>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/Dam.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
  </div>   */}     

                <div className='row'>
                    <div className='col-md-4 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/purna.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Purna Mohanty</h5>
                        <h6 className='text-mcolor'>Founder and CEO</h6>
                    </div>
                    <div className='col-md-4 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/niranjan.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Niranjan Tripathy</h5>
                        <h6 className='text-mcolor'>Chief People Officer</h6>
                    </div> 
                    <div className='col-md-4 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/don.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Don Smith</h5>
                        <h6 className='text-mcolor'>Silicon Architect</h6>
                    </div> 
                    <div className='col-md-4 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/milo.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Michael Loe Fernandez</h5>
                        <h6 className='text-mcolor'>Design Engineering</h6>
                    </div> 
                    
                    
                    
                    
                    <div className='col-md-4 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/rockey.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Rocky Arce</h5>
                        <h6 className='text-mcolor'>Software Engineering</h6>
                    </div> 
                    {/*<div className='col-md-3 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/mukesh.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Mukesh Sukla</h5>
                        <h6 className='text-mcolor'>R&D and Programs</h6>
                    </div> */}
                    <div className='col-md-4 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/benny.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Benedict Joseph Guevarra</h5>
                        <h6 className='text-mcolor'>Simulation Engineering</h6>
                    </div>        
                    {/*<div className='col-md-3 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/pradeep.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Pradeep Chimalapati</h5>
                        <h6 className='text-mcolor'>Sales, Business Development</h6>
                    </div> */}
                   {/* <div className='col-md-3 align-items-center justify-content-center text-center'>
                        <img src='./images/leaders/wary.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-4'/>
                        <h5 className='text-mcolor'>Nijwm Wary</h5>
                        <h6 className='text-mcolor'>Analog Engineering</h6>
                    </div> 
                    */}
                </div>

                 
                
            </div>
        </div>
    </>
  )
}

export default About